<template>
  <div>
    <b-button
      variant="success"
      size="sm"
      class="mb-1"
      :disabled="isOfferVatOld === isOfferVat || isLoading"
      @click="saveGlobalConfig"
    >
      <span v-if="!isLoading">{{ $t('Save') }}</span>
      <b-spinner
        v-else
        small
        variant="light"
      />
    </b-button>

    <b-card>
      <b-form-checkbox
        v-model="isOfferVat"
        checked="false"
        name="check-button"
        variant="primary"
        switch
        inline
        class="w-100 py-25"
      >
        {{ $t('isOfferVat') }}
      </b-form-checkbox>
    </b-card>

    <b-button
      variant="success"
      :disabled="(isOfferVatOld === isOfferVat) || isLoading"
      @click="saveGlobalConfig"
    >
      <span v-if="!isLoading">{{ $t('Save') }}</span>
      <b-spinner
        v-else
        small
        variant="light"
      />
    </b-button>
  </div>
</template>

<script>
import { GET_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'

export default {
  data: () => ({
    isLoading: false,
    isOfferVatOld: false,
    isOfferVat: false,
  }),
  mounted() {
    this.loadGlobalConfig()
  },
  methods: {
    loadGlobalConfig() {
      this.isLoading = true

      this.$store.dispatch(`system/${GET_SETTINGS}`)
        .then(item => {
          // eslint-disable-next-line no-multi-assign
          this.isOfferVat = this.isOfferVatOld = item.overwriteVatInCart
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    saveGlobalConfig() {
      this.isLoading = true

      const payload = {
        overwriteVatInCart: this.isOfferVat,
      }

      this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
        .then(() => {
          this.isOfferVatOld = this.isOfferVat

          this.showToast('success', this.$i18n.t('ChangesSaved'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
